import { Button, Flex, TextInput, Textarea, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import React, { useState } from "react";
import { useAppData } from "../../../contexts/AppContext";
import {Order, User} from "../../../constants";
import { input_styles } from "../../../constants/theme";

function EditInternalNote({
  order,
  onSubmit,
}: {
  order : Order;
  onSubmit: () => void;
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    patientsData: {savePatient},
    ordersData: {saveOrder},
  } = useAppData();
  const form = useForm<any>({
    initialValues: {
      internalNotes: order?.internalNotes || "",
    },
  });

  const handleRefund = async (values: any) => {
    setIsSubmitting(true);

    await saveOrder(
      {
        internalNotes: values?.internalNotes,
        id: order?.id,
      } as any,
      { refetch: false }
    );
    order.internalNotes = values?.internalNotes;
    setIsSubmitting(false);
    onSubmit();
  };
  return (
    <form onSubmit={form.onSubmit((values) => handleRefund(values))}>
      <Flex direction={"column"} gap={"lg"}>
        <Title size="xl"></Title>

        <Textarea
          rows={10}
          radius={"md"}
          styles={input_styles}
          size="xl"
          placeholder="Internal Notes"
          label="Internal Notes"
          name="internalNotes"
          {...form.getInputProps("internalNotes")}
        />

        <Button
          type="submit"
          loading={isSubmitting}
          size="lg"
          radius={"xl"}
          style={{ alignSelf: "center" }}
        >
          Save
        </Button>
      </Flex>
    </form>
  );
}

export default EditInternalNote;
