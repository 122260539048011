import {
  BatchRuleActionType,
  BatchRuleConditionType,
  MessageStatus,
  OrderStatus,
  Specie,
} from "./enums";

export interface IAdminLog {
  title: string;
  description: string;
  createdAt?: Date;
}
export interface AdminCol {
  email: string;
  name: string;
  phone: string;
  role: string; // (eg. superadmin, admin)
  isActive?: boolean;
  lastLogin?: Date;
  createdAt?: Date;
  updatedAt?: Date;
  "admin-logs"?: IAdminLog[];
}

export interface MessageThread {
  threadId: string;
  users: string[]; // Array of Ref to User / Ref to Admin
  lastMessage: string;
  lastMessageSentBy: string; // Ref to User / Ref to Admin
  lastMessageTimestamp: Date;
  content: string;
  sentBy: string;
  timestamp: Date;
  messages: {
    messageId: string; // Document ID
    sentBy: string; // Ref to User / Ref to Admin
    status: MessageStatus; // (sent, received, read)
    timestamp: Date;
    content: string;
    isDeleted: boolean; // (defaults to false)
  };
}

export interface MessageTemplate {
  createdAt?: Date; // Timestamp;
  createdBy?: string; // Ref to Admin
  title: string;
  content: string;

  id?: string;
}

export interface Notification {
  toUser: string; // Ref to User
  type: string;
  title: string;
  subTitle: string;
  createdAt: Date;
}

// This collection will be used for patients, since they are the end users of the web app.
export interface User {
  id?: string;
  firstName: string; //
  lastName: string; //
  dateOfBirth: Date; // The user's date of birth, used for identity confirmation (Not required for pets).
  phone: string; //
  email?: string; //
  species: Specie; // (enums: human, pet)
  doctor: string; //
  termsAccepted?: boolean; // Indicates whether the user has agreed to the terms of service and privacy policy.
  address?: {
    street: string; //
    street2: string; //  (optional)
    city: string;
    state: string;
    postalCode: string;
  };
  sameAddress?: boolean;
  billingAddress?: // (optional, will be supplied if sameAddress is false)
  {
    street: string;
    street2: string; //  (optional)
    city: string;
    state: string;
    postalCode: string;
  };
  connectedAccounts?: Object[]; // Array of Objects
  user?: string; // Ref to User
  addedAt?: Date; // Timestamp
  isConnected?: boolean; // This will identify if an user is already connected to by some other user
  allergies?: string[]; // Array of Strings - A list of any drug allergies the user has.
  medicalConditions?: string[]; // Array of Strings - A list of any existing medical conditions the user has.
  internalNotes?: string; //  (optional)
  signature?: string; //  - An image of user’s hand signature
  "user-logs"?: { // retired | just keeping the existing thing so we could may be use this in future
    title: string;
    description: string;
    createdAt?: Date;
    createdBy?: string; // Ref to Admin / Ref to User
  }[];
}

export interface Order {
  userId: string; // Reference
  currentStatus: {
    status: OrderStatus; // The current status of the order (enums: "waiting-response", "ordered", "refused", “wants-call”, “shipped”,  “delivered”)
    date: Date; // The date at which the status was changed
  };
  statusHistory: {
    status: OrderStatus;
    date: Date;
  }[];
  medications: {
    metadata: any;
    itemName: string; // The name of the item or medication.
    price: number; // The price of the item.
    quantity: number; // The quantity of the item ordered.
    dosageForm: string;
    autoRefill?: boolean;
    flavour?: string;
  }[]; // Array of Objects - A list of items within the order.

  subtotal: number; // The total price of all items before adding delivery fees and taxes.
  deliveryFee: number; // The fee associated with the delivery of the order.
  tax: number; // The tax amount for the order.
  total: number; // The total amount to be paid for the order.
  signatureRequired: boolean; // Indicates if a signature is required upon delivery.
  paymentMethod: {
    // Details of the payment method used for the order.
    cardNumber: string; // The credit card number used for the order.
    cardHolderName: string; // The name on the credit card.
    expirationMonth: number; // The expiration month of the credit card.
    expirationYear: number; // The expiration year of the credit card.
    securityCode: string; // The security code (CVV) of the credit card.
    zipCode: string; // The ZIP code associated with the credit card.
  };

  isSelfPickup: boolean;
  shippingMethod: string; // (Optional, not required if isSelfPickup is true) The method of shipping chosen for the order (e.g., UPS Ground).
  emailForConfirmation: string; // An email address provided to receive order confirmation and updates.
  prescriptions: string; // Array of Ref to Prescriptions
  notes: string;

  id?: string;
  rxNumber: string;

  [key: string]: any;

  autoRefill?: boolean;
  isAutoRefillVisible?: boolean;

  metadata?: any;
  createdAt?: any;
  pregBF?: boolean;
  totalAmount?: number;
  internalNotes?: string;
  signatureWaived?: boolean;
  "order-logs"?: {
    title: string;
    description: string;
    createdAt?: Date;
    createdBy?: string;
  }[];
}

export interface Prescription {
  userId: string; // Reference to User - A reference link to the user's document in the users collection.
  medication: string; // The name of the medication prescribed.
  dosage: string; // The dosage instructions for the medication. (eg. cream, pill)
  quantity: number; // The quantity of medication prescribed.
  price: number; //
  altQuantity: number;
  altPrice: number;
  instructions: string; // Specific instructions for taking or using the medication.
  refillInfo: // Information regarding refills of the prescription.
  {
    refillsRemaining: Number; // The number of refills remaining for the prescription.
    nextRefillDate: Date; // The date when the next refill is due.}
  };
}

export enum LogicalOperators {
  AND = "AND",
  OR = "OR",
}

export interface BatchRule {
  conditionType: BatchRuleConditionType; // The type of condition to evaluate (e.g., "medication-contains", "price-exceeds").
  conditionKey: string; // The specific key or attribute to check (e.g., "medication", "price").
  conditionValue: string[] | number[]; // Array of String/Number  The value to check for in the condition (e.g., "Heparin", 100). The condition values can be multiple.
  action: BatchRuleAction; //Ref to Action
  isActive?: boolean; // Indicates whether the rule is currently active or not.

  id?: string;
  secondCondition?: {
    key: string;
    value: string;
    logicalOp: LogicalOperators;
  };
}

export interface BatchRuleAction {
  createdAt?: Date;
  createdBy?: string; // Ref to Admin
  title: BatchRuleActionType; // (e.g., "check-for-pregnant-breastfeeding", "require-signature", "auto-refill", "free-shipping").

  id?: string;
  slug?: string;
}
