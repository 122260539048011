/** @format */

import {
	Flex,
	Input,
	InputProps,
	PolymorphicComponentProps,
	rem,
} from "@mantine/core";
import React from "react";
import { useAppData } from "../contexts/AppContext";
import styled from "styled-components";
import { colors } from "../constants";

interface Props {
	buttons?: React.ReactNode;
	searchProps?: PolymorphicComponentProps<"input", InputProps>;
	subtitle?: string;
	title?: string;
}

function PageHeader(props: Props) {
	const { buttons, searchProps, title, subtitle = "" } = props;
	const { currentRouteData } = useAppData();

	return (
		<Flex
			wrap={"wrap"}
			pb={25}
			justify={"space-between"}
			align={"center"}
			style={{
				...(currentRouteData.currentRoute.path?.includes("/patient-look-up") && {
					borderBottom: `1px solid ${colors.primaryColor}`,
				}),
			}}
			w={"100%"}>
			<Flex direction={"column"} gap={5}>
				<Title>{title || currentRouteData.currentRoute.name}</Title>
				<Subtitle>{subtitle}</Subtitle>
			</Flex>
			<Flex justify={"center"}>
				<Input
					w={450}
					className="colored-placeholder"
					variant="filled"
					size="xl"
					radius={"xl"}
					bg={colors.bg}
					styles={{
						input: {
							background: colors.bg,
							borderColor: "#6E23CF",
							borderWidth: "3px",
						},
						wrapper: {
							borderRadius: rem("44px"),
						},
					}}
					{...searchProps}
				/>
			</Flex>

			<Flex justify={"flex-end"} gap={10}>
				{buttons}
			</Flex>
		</Flex>
	);
}

export default PageHeader;

const Title = styled.div`
	font-family: Manrope;
	font-size: 18px;
	font-weight: 600;
	line-height: 21px;
	letter-spacing: 0em;
	text-align: left;
	color: #212121;
`;

const Subtitle = styled.div`
	font-family: Manrope;
	font-size: 16px;
	font-weight: 400;
	line-height: 21px;
	letter-spacing: 0em;
	text-align: left;
	color: #b3b3b3;
`;
