import { useState } from "react";
import { Order, User } from "../../../constants";
import { Button, Flex, Space, Text, TextInput } from "@mantine/core";
import { input_styles } from "../../../constants/theme";
import { useForm } from "@mantine/form";
import axios from "axios";
import { notifications } from "@mantine/notifications";
import { addSubcollectionDoc } from "../../../repositories/firebase";
import { COLLECTIONS } from "../../../constants/global";
import { useAppData } from "../../../contexts/AppContext";

const BASE_URL =
  "https://us-central1-art-of-med-text-to-order.cloudfunctions.net/app";
interface Props {
  order: Order;
  onSubmit: () => void;
  patient: User;
  type: "charge" | "refund";
}

function RefundPatientForm({ onSubmit, order, patient, type }: Props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  let isChargePatientMode = type === "charge";
  const { user } = useAppData();

  const formatCardNumber = (cardNumber: string) => {
    if (!cardNumber) return "";
    const lastFourDigits = cardNumber.slice(-4);
    const maskedDigits = "*".repeat(cardNumber.length - 4);
    return maskedDigits + lastFourDigits;
  };

  const maskSecurityCode = (securityCode: string) => {
    if (!securityCode) return "";
    return "*".repeat(securityCode.length);
  };

  const form = useForm<any>({
    initialValues: {
      cardNumber: order?.paymentMethod?.cardNumber || "",
      securityCode: order?.paymentMethod?.securityCode || "",
      expiryMonth: order?.paymentMethod?.expirationMonth || "",
      expiryYear: order?.paymentMethod?.expirationYear || "",
      transactionId: "",
      amount: "",
    },
    validate: {
      cardNumber: (value) => (value === "" ? "Card Number required" : null),
      securityCode: (value) => (value === "" ? "Security Code required" : null),
      expiryMonth: (value) =>
        value === "" ? "Expiration Month required" : null,
      expiryYear: (value) => (value === "" ? "Expiration Year required" : null),
      transactionId: (value) =>
        value === "" && !isChargePatientMode ? "Transaction ID required" : null,
      amount: (value) =>
        value === "" && isChargePatientMode ? "Amount required" : null,
    },
  });

  const handleRefund = async (values: any) => {
    setIsSubmitting(true);

    try {
      const response = await axios.post(`${BASE_URL}/refundTransaction`, {
        data: {
          cardInfo: {
            cardNumber: values.cardNumber.replace(/\*/g, ""),
            cardCode: order?.paymentMethod?.securityCode,
            expiryDate: values.expiryMonth + "/" + values.expiryYear,
          },
          transactionId: values.transactionId,
          amount: order?.totalAmount,
        },
      });

      if (response.data.success) {
        notifications.show({
          title: "Refund request successful!",
          message: response.data.message,
        });
        // add logs
        // await addSubcollectionDoc(
        //   COLLECTIONS.USERS,
        //   patient?.id,
        //   "user-logs",
        //   {
        //     title: `Payment refunded.`,
        //     description: `Payment has been refunded of amount $${order?.totalAmount} by ${patient?.firstName}.`,
        //   },
        //   {
        //     createdAt: true,
        //   }
        // );
        await addSubcollectionDoc(
            COLLECTIONS.ORDERS,
            order?.id,
            "order-logs",
            {
              title: `Payment refunded.`,
              description: `Payment has been refunded of amount $${order?.totalAmount} by ${patient?.firstName}.`,
            },
            {
              createdAt: true,
            }
        );
      } else {
        notifications.show({
          title: "Refund request failed!",
          message: response.data.message,
          color: "red",
        });
      }
    } catch (error: any) {
      notifications.show({
        title: "Refund request failed!",
        message: error?.response?.data?.message,
        color: "red",
      });
    } finally {
      setIsSubmitting(false);
    }
    onSubmit();
  };

  const handleCharge = async (values: any) => {
    setIsSubmitting(true);
    try {
      const response = await axios.post(`${BASE_URL}/chargeCreditCard`, {
        data: {
          cardInfo: {
            cardCode: order?.paymentMethod?.securityCode,
            cardNumber: values.cardNumber,
            expiryDate: values.expiryMonth + "/" + values.expiryYear,
          },
          address: {
            firstName: patient.firstName,
            lastName: patient.lastName,
            company: "Art of Med",
            address: patient.address.street,
            city: patient.address.city,
            state: patient.address.state,
            zip: patient.address.postalCode,
            country: patient.address.state,
          },
          amount: values?.amount,
        },
      });
      if (response.data.success) {
        notifications.show({
          title: "Payment request submitted successfully!",
          message: response.data.message,
        });
        // add logs
        // await addSubcollectionDoc(
        //   COLLECTIONS.USERS,
        //   patient?.id,
        //   "user-logs",
        //   {
        //     title: `Auto re-fill Payment charged.`,
        //     description: `Payment has been charged of amount $${values?.amount} by ${user?.email} for auto re-fill.`,
        //   },
        //   {
        //     createdAt: true,
        //   }
        // );
        await addSubcollectionDoc(
            COLLECTIONS.ORDERS,
            order?.id,
            "order-logs",
            {
              title: `Auto re-fill Payment charged.`,
              description: `Payment has been charged of amount $${values?.amount} by ${user?.email} for auto re-fill.`,
            },
            {
              createdAt: true,
            }
        );
      } else {
        notifications.show({
          title: "Payment charge request failed",
          message: response.data.message,
          color: "red",
        });
      }
    } catch (error: any) {
      notifications.show({
        title: "Payment charge request failed!",
        message: error?.response?.data?.message,
        color: "red",
      });
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleSubmit = async (values: any, type: string) => {
    if (type === "charge") {
      handleCharge(values);
    } else {
      handleRefund(values);
    }
  };

  return (
    <form onSubmit={form.onSubmit((values) => handleSubmit(values, type))}>
      <Flex direction={"column"} gap={"lg"}>
        <Flex direction={"column"}>
          <Text size="xl">
            Name:{" "}
            <strong>{order?.paymentMethod?.cardHolderName || "N/A"}</strong>
          </Text>
          <Space h={"xs"} />

          {isChargePatientMode ? (
            <TextInput
              maw={"200px"}
              radius={"md"}
              styles={input_styles}
              size="xl"
              placeholder="Amount"
              label="Amount"
              name="amount"
              type="number"
              {...form.getInputProps("amount")}
            />
          ) : (
            <Text size="xl">
              Total Amount:{" "}
              <strong>
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(order?.totalAmount || 0)}
              </strong>
            </Text>
          )}
        </Flex>

        <Flex gap={"md"}>
          <TextInput
            radius={"md"}
            styles={input_styles}
            size="xl"
            placeholder="Card Number"
            label="Card Number"
            name="cardNumber"
            readOnly
            {...form.getInputProps("cardNumber")}
            value={formatCardNumber(order?.paymentMethod?.cardNumber || "")}
          />
          <TextInput
            radius={"md"}
            styles={input_styles}
            size="xl"
            placeholder="Security Code"
            label="Security Code"
            name="securityCode"
            readOnly
            {...form.getInputProps("securityCode")}
            value={maskSecurityCode(order?.paymentMethod?.securityCode || "")}
          />
        </Flex>
        <Flex gap={"md"}>
          <TextInput
            // maw={"200px"}
            radius={"md"}
            styles={input_styles}
            size="xl"
            placeholder="Exp Month"
            label="Expiration Month"
            name="expiryMonth"
            readOnly
            {...form.getInputProps("expiryMonth")}
          />
          <TextInput
            // maw={"200px"}
            radius={"md"}
            styles={input_styles}
            size="xl"
            placeholder="Exp Year"
            label="Expiration Year"
            name="expiryYear"
            readOnly
            {...form.getInputProps("expiryYear")}
          />
        </Flex>

        {isChargePatientMode ? null : (
          <TextInput
            maw={"640px"}
            radius={"md"}
            styles={input_styles}
            size="xl"
            placeholder="Transaction ID"
            label="Transaction ID"
            name="transactionId"
            {...form.getInputProps("transactionId")}
          />
        )}

        <Button
          type="submit"
          loading={isSubmitting}
          size="lg"
          radius={"xl"}
          style={{ alignSelf: "center" }}>
          {isChargePatientMode ? "Charge" : "Refund"}
        </Button>
      </Flex>
    </form>
  );
}

export default RefundPatientForm;
