import { LoadingOverlay } from "@mantine/core";
import {
    createContext,
    PropsWithChildren,
    useCallback,
    useContext, useEffect, useState,
} from "react";
import { useAuth } from "../hooks/useAuth";
import { User } from "@firebase/auth";
import { useCurrentRoute } from "../hooks/useCurrentRoute";
import {
  UseAdmins,
  UseCurrentRoute,
  UseOrders,
  UsePatients,
} from "../constants";
import { useAdmins } from "../hooks/useAdmins";
import { usePatients } from "../hooks/usePatients";
import { UseBatchRules, useBatchRules } from "../hooks/useBatchRules";
import { UseTemplates, useTemplates } from "../hooks/useTemplates";
import { UseMessages, useMessages } from "../hooks/useMessages";
import { UseBatchPreview, useBatchPreview } from "../hooks/useBatchPreview";
import { useOrders } from "../hooks/useOrders";
import {collection, getDocs} from "firebase/firestore";
import {db} from "../firebase";

interface IAppData {
  user: User | null;
  currentRouteData: UseCurrentRoute;
  adminsData: UseAdmins;
  patientsData: UsePatients;
  batchRulesData: UseBatchRules;
  templatesData: UseTemplates;
  batchPreviewData: UseBatchPreview;
  ordersData: UseOrders;
  messagesData: UseMessages;
  updateUnreadMessagesCount: () => Promise<void>;
  authenticated: boolean;
  setAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  getKeyByValue : (object: any, value: string) => string
}

export const AppDataContext = createContext<IAppData>({} as IAppData);

export function useAppData() {
  return useContext(AppDataContext);
}

export default function AppDataProvider(props: PropsWithChildren<any>) {
  // const [authLoading, setAuthLoading] = useState(true);
  const { authLoading, user } = useAuth();
  const currentRouteData = useCurrentRoute();
  const adminsData = useAdmins(user);
  const patientsData = usePatients();
  const batchRulesData = useBatchRules();
  const templatesData = useTemplates();
  const batchPreviewData = useBatchPreview();
  const ordersData = useOrders();
  const messagesData = useMessages();
  const [authenticated, setAuthenticated] = useState(false);
  const isAppDataLoading = useCallback(() => {
    return authLoading;
  }, [authLoading]);

    async function updateUnreadMessagesCount() {
        try {
            const messageThreadRef = collection(db, 'message-thread');
            const querySnapshot = await getDocs(messageThreadRef);
            let totalUnreadMessages = 0;
            querySnapshot.forEach((doc) => {
                const threadData = doc.data();
                if (threadData.unreadMessages && typeof threadData.unreadMessages === 'number') {
                    totalUnreadMessages += threadData.unreadMessages;
                }
            });
            const elements = document.getElementsByClassName('cnt_holder');
            for (let i = 0; i < elements.length; i++) {
                const element = elements[i];
                element.textContent = `(${totalUnreadMessages})`;
            }
        } catch (error) {
            console.error('Error getting unread messages sum:', error);
            throw error;
        }
    }


    function getKeyByValue(object: any, value: string): string {
        return Object.keys(object).find(key => object[key] === value) || value;
    }


    return (
    <AppDataContext.Provider
      value={{
        user,
        currentRouteData,
        adminsData,
        patientsData,
        batchRulesData,
        templatesData,
        batchPreviewData,
        ordersData,
        messagesData,
        updateUnreadMessagesCount,
        authenticated,
        setAuthenticated,
        getKeyByValue
      }}>
      {isAppDataLoading() ? <LoadingOverlay visible /> : props.children}
    </AppDataContext.Provider>
  );
}
